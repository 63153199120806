import React, { memo } from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import kebabCase from 'lodash/kebabCase';
import { Card, ImageCard } from '@components';
import { FiTag } from 'react-icons/fi';
import './blog-post.scss';

//TODO: Discuss dangerouslySetInnerHTML
const BlogPost = ({ data }) => {
  
  const { markdownRemark: post } = data;
  return (
    <div>
      <Helmet>
        <meta name="keywords" content={post.frontmatter.tags} />
      </Helmet>
      <div className="on-blog-post-main">
        <ImageCard
          filename={post.frontmatter.frontImage}
          className="on-blog-post-title-image"
          imageClassName="on-blog-post-title-image-inner"
          contentClassName="on-blog-post-title-image-content"
        />
        <h2 className="on-blog-post-title">{post.frontmatter.title}</h2>
        <Card className="on-inverted on-no-animation-inverted on-blog-post-card">
          <div className="on-blog-post-item" dangerouslySetInnerHTML={{ __html: post.html }} />
        </Card>

        <div className="on-blog-post-tags">
          {post.frontmatter.tags.map(tag => (
            <Link className="on-blog-post-tag-item" to={`/tags/${kebabCase(tag)}`} key={kebabCase(tag)}>
              <Card className="on-tag-container ">
                <span className="circle">
                  <FiTag className="icon" />
                </span>
                <span className="text">
                  <h3>{tag}</h3>
                </span>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query($slug: String!) {
    ...BlogPostQueryFragment
  }
`;

export default memo(BlogPost);
